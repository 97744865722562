<template>
  <div class="consult_card">
    <p class="wx_title">微信咨询</p>
    <div class="qrcode">
      <img :src="userInfo.salesJobWechatQrcode" alt="" />
    </div>
    <div class="border"></div>
    <p class="qq_title">QQ咨询</p>
    <p class="qq_message">
      <img :src="require('@/assets/images/QQ.png')" alt="" /><span>{{
        userInfo.salesJobQq
      }}</span>
    </p>
    <div class="border"></div>
    <p class="service_tel">服务电话</p>
    <p class="service_class">
      <img :src="require('@/assets/images/telIcon.png')" alt="" /><span>{{
        userInfo.salesJobPhoneNumber
      }}</span>
    </p>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      userInfo: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    getData(userInfo) {
      this.userInfo = userInfo;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.consult_card {
  font-family: Source Han Sans CN;
  width: 135px;
  height: 235px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  padding: 13px 17px 15px 20px;
  text-align: center;
  .wx_title {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
  .border {
    width: 100%;
    margin: 10px 0px;
    height: 1px;
    border: 1px solid #eeeeee;
    opacity: 0.5;
  }
  .qq_title {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
  .qq_message {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .qrcode {
    width: 91px;
    height: 88px;
    margin: 5px 0px 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  .service_tel {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
  .service_class {
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
    }
  }
}
</style>
