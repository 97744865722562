import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import store from '../store';
import Cookies from 'js-cookie';  // 导入 js-cookie
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      context: '欢迎登录'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/loginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      context: '用户注册'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/RegiSter.vue')
  },
  {
    path: '/orderPay/:orderId',
    name: 'orderpaymentPage',
    meta: {
      context: '订单支付'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/orderpayment/orderpaymentPage.vue'),
  },
  {
    path: '/orderPayment/success',
    name: 'SuccessPage',
    meta: {
      context: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/SuccessPage.vue')
  },
  {
    path: '/infoCenter',
    name: 'infoCenter',
    meta: {
      context: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/infoCenter/index.vue')
  },
  {
    path: '/helpCenter',
    name: 'helpCenter',
    meta: {
      context: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/helpCenter/index.vue')
  },
  {
    path: '/Recharge',
    name: 'Recharge',
    meta: {
      context: '充值余额'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/recharge/rechargeBalance.vue'),
  },
  {
    path: '/payment',
    name: 'pay',
    meta: {
      context: '订单结算'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/payment/payView.vue')
  },
  {
    path: '/product/:goodsId',
    name: 'productDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/index.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      context: '购物车'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cart/cartView.vue')
  },
  {
    path: '/searchResPage',
    name: 'searchResPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/search/searchResPage.vue')
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/index.vue'),
    redirect: '/userCenter/userCenterIndex', // 设置重定向
    children: [
      {
        path: 'userCenterIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/userCenterIndex.vue'),
      },
      {
        path: 'myOrder',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myOrder/myOrder.vue'),
      },
      {
        path: 'myInvoice',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myInvoice/myInvoice.vue'),
      },
      {
        path: 'myAfterSales',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myAfterSales/index.vue'),
      },
      {
        path: 'myBalance',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myBalance/myBalance.vue'),
      },
      {
        path: 'myCoupons',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myCoupons/myCoupons.vue'),
      },
      {
        path: 'creditLimit',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/creditLimit/creditLimit.vue'),
      },
      {
        path: 'myTeam',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myTeam/myTeam.vue'),
      },
      {
        path: 'deliveryAddress',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/deliveryAddress/deliveryAddress.vue'),
      },
      {
        path: 'invoiceHeader',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/invoiceHeader/index.vue'),
      },
      {
        path: 'basicInformation',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/basicInformation/index.vue'),
      },
      {
        path: 'myMessage',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myMessage/index.vue'),
      },
      {
        path: 'suggestPage',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/suggestPage/index.vue'),
      },
      {
        path: 'myInquiry',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myInquiry/index.vue'),
      },
      {
        path: 'myFrequentPurchases',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myFrequentPurchases/index.vue'),
      },
      {
        path: 'myIngot',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/myIngot/index.vue'),
      },
      {
        path: 'accountSecurity',
        component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/accountSecurity/index.vue'),
      },
    ]
  },
  {
    path: '/userCenter/myOrder/myOrderDetails/:orderId',
    name: 'myOrderDetails',
    meta: {
      context: '我的订单'
    },
    component: () => import('../views/usercenter/components/orderRelated/myOrderDetails.vue'),
  },
  {
    path: '/userCenter/myOrder/invoiceRelated/:invoiceApplyId',
    name: 'invoiceRelated',
    component: () => import('../views/usercenter/components/invoiceRelated/invoiceRelated.vue'),
  },
  {
    path: '/userCenter/creditLimit/billDetails/:customerId',
    name: 'billDetails',
    component: () => import('../views/usercenter/components/creditLimit/billDetails.vue'),
  },
  {
    path: '/ticketRelated/details/:workOrderId',
    name: 'ticketDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/details.vue')
  },
  {
    path: '/ticketRelated/initiateAfterSales/:orderId',
    name: 'initiateAfterSales',
    meta: {
      context: '发起售后'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/initiateAfterSales.vue')
  }, 
  {
    path: '/userCenter/accountSecurity/modifyPhoneNumber',
    name: 'initiateAfterSales',
    meta: {
      context: '修改密码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/components/accountSecurity/modifyPhoneNumber.vue')
  },
  {
    path: '/ticketRelated/suggestionFeedback',
    name: 'suggestionFeedback',
    meta: {
      context: '发起建议反馈'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/suggestionFeedback.vue')
  },
  {
    path: '/ticketRelated/initRequest',
    name: 'initRequest',
    meta: {
      context: '发起询价'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketRelated/initRequest.vue')
  },
  {
    path: '/advancedSearch',
    name: 'advancedSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/search/advancedSearch.vue')
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    meta: {
      context: '重置密码'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/ResetPassword.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/weixin_login',
    name: 'weixin_login',
    meta: {
      context: '绑定账户'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/components/thirdPartyLogin.vue')
  },
  {
    path: '/zhifubao_login',
    name: 'zhifubao_login',
    meta: {
      context: '绑定账户'
    },
    component: () => import(/* webpackChunkName: "about" */ '@/components/thirdPartyLogin.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const authToken = Cookies.get('authToken'); // 获取 token
  const userInfo = localStorage.getItem('userInfo'); // 获取 token
  if (to.path === '/login' && authToken && userInfo) {
    // 如果已经登录并且访问登录页面，则重定向到首页
    next({ path: '/' });
  } else {
    next();
  }
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router
