import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user
  },
  state: {
    user: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit('setUser', user);
          resolve();
        }, 1000);
      });
    },
    logout({ commit }) {
      console.log(123)
      commit('setUser', null);
    }
  },
  getters: {
    isLoggedIn: state => !!state.user,
    currentUser: state => state.user
  }
});
