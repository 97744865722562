<template>
  <div class="sidebar_box" v-if="showBar">
    <div class="bar" @click="goLogin" v-if="!authToken">
      <img :src="require('@/assets/icons/userIcon.png')" alt="" />
      <p>登录</p>
    </div>
    <div class="bar" @click="goRegister" v-if="!authToken">
      <img :src="require('@/assets/icons/regiserIcon.png')" alt="" />
      <p>注册</p>
    </div>
    <div class="bar" @click="goCart" v-if="authToken">
      <img :src="require('@/assets/icons/cartIcon.png')" alt="" />
      <p>购物车</p>
    </div>
    <div class="bar" v-if="authToken">
      <img :src="require('@/assets/icons/kfIcon.png')" alt="" />
      <p>专属<br />客服</p>
    </div>
    <div class="bar">
      <img :src="require('@/assets/icons/aiIcon.png')" alt="" />
      <p>元素<br />问问</p>
    </div>
    <div class="bar consultBar" @click="contactSales" ref="consultBar">
      <img :src="require('@/assets/icons/phoneIcon.png')" alt="" />
      <p>联系<br />销售</p>
    </div>
    <div class="bar" v-if="showButton" @click="scrollToTop">
      <img :src="require('@/assets/icons/topIcon.png')" alt="" />
      <p>回到<br />顶部</p>
    </div>
    <consult ref="consult" class="consult" v-show="isShow"></consult>
  </div>
</template>

<script>
import { getSalesFromHomePageApi } from "@/api/index";
import consult from "@/components/consult.vue";
export default {
  components: { consult },
  props: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      findHomeSalesObj: {},
      authToken: this.$cookies.get("authToken"), // 获取 token
      showButton: false, // 控制按钮的显示
      isShow: false,
      showBar: true,
    };
  },
  watch: {
    $route(to, form) {
      if (this.$route.path === "/home") {
        // this.authToken = this.$cookies.get("authToken");
        this.showBar = true;
      } else if (this.$route.path === "/login") {
        this.showBar = false;
      } else {
        // this.showBar = false;
      }
    },
  },
  computed: {},
  created() {
    if (this.$route.path === "/login") {
      this.showBar = false;
    }
    this.getSalesFromHome();
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("click", this.handleClickOutside);
  },
  methods: {
    goLogin() {
      this.$router.push({ path: "/login" }, () => {});
    },
    goRegister() {
      this.$router.push({ path: "/regiSter" });
    },
    goCart() {
      this.$router.push({ path: "/cart" });
    },
    async getSalesFromHome() {
      let res = await getSalesFromHomePageApi();
      if (res.code === 200) {
        this.findHomeSalesObj = res.data;
      }
    },
    //联系销售
    contactSales(event) {
      event.stopPropagation(); // 阻止点击事件冒泡
      this.$refs.consult.getData(this.findHomeSalesObj);
      this.isShow = !this.isShow; // 切换显示状态
    },
    handleClickOutside(event) {
      // 如果点击不在 consultBar 内，则隐藏 consult
      if (this.isShow && !this.$refs.consult.$el.contains(event.target)) {
        this.isShow = false;
      }
    },
    handleScroll() {
      // 判断页面滚动距离，显示或隐藏按钮
      this.showButton = window.scrollY > 0; // 超过300px时显示按钮
    },
    scrollToTop() {
      // 平滑滚动到顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style lang="scss" scoped>
.sidebar_box {
  width: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  right: 14px;
  top: 211px;
  .bar {
    width: 50px;
    height: 90px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    img {
      margin-top: 15px;
      width: 20px;
      height: 20px;
    }
    p {
      margin-top: 10px;
      font-weight: 300;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .consultBar {
    position: relative;
  }
  .consult {
    position: absolute;
    right: 60px;
    top: 225px;
  }
}
</style>
