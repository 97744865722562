<template>
  <div class="footer">
    <div class="features">
      <div
        class="feature-item"
        v-for="(feature, index) in features"
        :key="index"
      >
        <img class="imgstyle" :src="feature.icon" alt="feature icon" />
        <p>{{ feature.text }}</p>
      </div>
    </div>
    <div class="footer_border"></div>
    <div class="links">
      <div class="links_card">
        <div class="link-group" v-for="(group, index) in tabsData" :key="index">
          <a class="link-a">{{ group.typeName }}</a>
          <ul>
            <li
              v-for="(link, linkIndex) in group.helpInfoList
                ? group.helpInfoList
                : []"
              :key="linkIndex"
            >
              <a @click="goHelpPage(group, link)">{{ link.title }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="contact">
        <div class="qrcode">
          <img :src="qrcodeImage" alt="QR code" />
          <div class="social-icons">
            <img :src="wechatIcon" alt="WeChat" />
            <img :src="douyinIcon" alt="Douyin" />
            <img :src="kuaishouIcon" alt="Kuaishou" />
            <img :src="xiaohongshuIcon" alt="Xiaohongshu" />
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="copyright_box">
        <p style="padding-bottom: 5px">
          Copyright © 2017-2024 b2star. All Rights Reserved.
          江苏星科云信息科技有限公司 版权所有
        </p>
        <p class="copyright_text">
          <span @click="goLinks(1)">
            <img :src="require('@/assets/icons/cnIcon3.png')" alt="" />
            苏ICP证B2-20200443号</span
          >
          <span @click="goLinks(3)">苏ICP备11026111号-2</span
          ><span @click="goLinks(2)">
            <img
              :src="require('@/assets/icons/cnIcon1.png')"
              alt=""
            />企业资质</span
          >
          <span @click="goLinks(4)">
            <img
              :src="require('@/assets/icons/cnIcon2.png')"
              alt=""
            />中国互联网违法和不良信息举报中心</span
          >
        </p>
        <p class="friendly_links">
          友情链接：
          <span
            @click="goFriendlyLinks(item)"
            :href="item.dictValue"
            v-for="(item, index) in linksData"
            :key="index"
            >{{ item.dictLabel }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFindBottomHelpInfoTypeListApi,
  getFriendlyLinksApi,
} from "@/api/index";
export default {
  data() {
    return {
      features: [
        {
          icon: require("@/assets/icons/many.png"),
          text: "品牌众多\n品类齐全",
        },
        {
          icon: require("@/assets/icons/fast.png"),
          text: "多仓直发\n极速配送",
        },
        {
          icon: require("@/assets/icons/good.png"),
          text: "正品保障\n专业服务",
        },
        {
          icon: require("@/assets/icons/save.png"),
          text: "源头价格\n实惠多多",
        },
      ],
      tabsData: [],
      linksData: [],
      qrcodeImage: require("@/assets/qrcode.png"),
      wechatIcon: require("@/assets/wechat.png"),
      douyinIcon: require("@/assets/douyin.png"),
      kuaishouIcon: require("@/assets/kuaishou.png"),
      xiaohongshuIcon: require("@/assets/xiaohongshu.png"),
    };
  },
  created() {
    this.getFindBottomHelpInfoTypeList();
    this.getFriendlyLinks();
  },
  methods: {
    async getFindBottomHelpInfoTypeList() {
      let res = await getFindBottomHelpInfoTypeListApi();
      if (res.code === 200) {
        this.tabsData = res.data;
      }
    },
    async getFriendlyLinks() {
      let res = await getFriendlyLinksApi();
      if (res.code === 200) {
        this.linksData = res.data;
      }
    },
    //跳转帮助页
    goHelpPage(group, link) {
      this.$router.push({
        path: "/helpCenter",
        query: {
          helpInfoId: link.helpInfoId,
          helpInfoTypeId: group.helpInfoTypeId,
        },
      });
    },
    goLinks(index) {
      if (index === 1) {
        this.$router.push(
          {
            path: "/helpCenter",
            query: {
              helpInfoId: 10001,
              helpInfoTypeId: 1,
            },
          },
          () => {}
        );
      } else if (index === 2) {
        this.$router.push(
          {
            path: "/helpCenter",
            query: {
              helpInfoId: 10002,
              helpInfoTypeId: 1,
            },
          },
          () => {}
        );
      } else if (index === 3) {
        window.open("https://beian.mps.gov.cn/#/query/webSearch", "_blank");
      } else if (index === 4) {
        window.open("https://www.12377.cn/", "_blank");
      }
    },
    //跳转友链
    goFriendlyLinks(item) {
      if (item.dictValue) {
        window.open(item.dictValue, "_blank");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  background-color: #fff;
  text-align: center;
  font-family: Source Han Sans CN;
  overflow: hidden;
  .features {
    width: 1200px;
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    margin: 38px auto;
    .feature-item {
      display: flex;
      align-items: center;
      text-align: center;
      white-space: pre-wrap;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #333;

      .imgstyle {
        width: 39px;
        height: 41px;
        margin-right: 10px;
      }
    }
  }
  .footer_border {
    width: 1200px;
    height: 1px;
    background: #e8e8e8;
    margin: 0 auto;
  }

  .links {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 40px auto;
    .links_card {
      width: 990px;
      display: flex;
      justify-content: space-evenly;
      .link-group {
        text-align: left;
        width: 18%;

        .link-a {
          margin-bottom: 26px;
          display: inline-block;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #2f323e;
        }

        ul {
          list-style: none;
          padding: 0;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 14px;
          color: #999;

          li {
            margin-bottom: 5px;

            a {
              cursor: pointer;
              color: #999;
            }
          }
        }
      }
    }
    .contact {
      display: flex;
      justify-content: center;
      align-items: center;

      .qrcode {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
          width: 130px;
          height: 130px;
        }

        .social-icons {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            margin: 5px 0;
          }
        }
      }
    }
  }

  .copyright {
    background-color: #333;
    .copyright_box {
      width: 850px;
      padding: 30px 0px;
      color: #fff;
      font-size: 14px;
      line-height: 32px;
      margin: 0 auto;
      text-align: center;
      p {
        padding-bottom: 5px;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        margin-left: 40px;
      }
      .copyright_text {
        display: flex;
        span {
          text-align: center;
          display: flex;
          align-items: center;
          margin: 0 auto;
          cursor: pointer;
        }
      }
      .friendly_links {
        span {
          cursor: pointer;
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>
