import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "@/store";
import FingerprintJS from "fingerprintjs2";
import Cookies from "js-cookie"; // 导入 js-cookie
// import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 请求的基础URL
  timeout: 5000, // 请求超时时间
});
export let isRelogin = { show: false };
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 对应国际化资源文件后缀
axios.defaults.headers["Content-Language"] = "zh_CN";
function getFingerprint() {
  return new Promise((resolve, reject) => {
    FingerprintJS.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = FingerprintJS.x64hash128(values.join(""), 31);
      resolve(fingerprint);
      reject("");
    });
  });
}
// 请求拦截器
service.interceptors.request.use(
  async (config) => {
    try {
      // 生成指纹并设置到请求头
      config.headers["Yacoo-Fingerprint"] = await getFingerprint();
      // 检查本地存储中的授权token并设置到请求头
      const authToken = Cookies.get("authToken");
      if (authToken) {
        config.headers["Authorization"] = authToken;
      }
    } catch (error) {
      console.error("Error generating fingerprint:", error);
      return Promise.reject(error);
    }

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    console.error("request error", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    // 处理非200或10001的情况
    if (res.code !== 200 && res.code !== 10001) {
      if (res.code === 401) {
        if (!isRelogin.show) {
          isRelogin.show = true;
          MessageBox.confirm(
            "登录状态已过期，您可以继续留在该页面，或者重新登录",
            "系统提示",
            {
              confirmButtonText: "重新登录",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              isRelogin.show = false;
              Cookies.remove("authToken");
              localStorage.removeItem("userInfo");
              location.href = "/login";
            })
            .catch(() => {
              isRelogin.show = false;
            });
        }
        // 返回一个空的响应或错误标识，避免抛出错误中断后续代码
        return {
          code: 401,
          message: "无效的会话，或者会话已过期，请重新登录。",
        };
      } else if (res.code === 500) {
        Message({
          message: res.message,
          type: "error",
        });
        // 返回处理后的错误信息，但不影响后续代码
        return { code: 500, message: res.message };
      } else {
        Message({
          message: res.message,
          type: "error",
        });
        // 返回一个特定的响应
        return { code: res.code, message: res.message };
      }
    } else {
      // 正常返回数据
      return res;
    }
  },
  (error) => {
    console.error("response error", error);
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    // 返回错误，但不中断后续代码执行
    return { code: -1, message: error.message };
  }
);

export default service;
