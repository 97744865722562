import {login} from '@/api'
import { getToken, setToken } from '@/utils/auth'
import Cookies from 'js-cookie';  // 导入 js-cookie

const user = {
    state: {
        token: getToken(),
        name: '',
        avatar: '',
        roles: [],
        permissions: []
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_USER_ID: (state, userId) => {
            state.userId = userId
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        }
    },

    actions: {
        // 登录
        Login({ commit }, { userInfo, context }) {
            const username = userInfo.username.trim()
            const password = userInfo.password
            const code = userInfo.code
            const uuid = userInfo.uuid
            let data = {
                username: username,
                password: password,
                code: code,
                uuid: uuid
            }
            return new Promise((resolve, reject) => {
                login(data).then(res => {
                    if (res.data.token) {
                        Cookies.set('authToken', res.data.token, { expires: 30 });
                        commit('SET_TOKEN', res.data.token)
                        context.$message.success("登录成功")
                        resolve(res)          
                    }else{
                        context.$message.error(res.data.message)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default user
